.away{
    position: absolute;
    width: 100%;
    height: 100%;
    //background: rgba(255,255,255,0.5);
    z-index: 1;
    backdrop-filter: grayscale(1);
}

.status {
    position: absolute;
    right: 6px;
    bottom: 0px;
}