.rooms-grid {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: auto;
}

.rooms-grid-column {
    background-clip: padding-box;
}

.avatar-builder-grid {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: auto;
    max-height: 450px;
    overflow-x: hidden;
    overflow-y: auto;
}

.avatar-builder-grid-column {
    padding-right: 20px;
    width: 140px !important;
    background-clip: padding-box;
}

.room-menu {
    cursor: pointer;
    z-index: 7;

    .room-menu-dot {
        width: 6px;
        height: 6px;
        border-radius: 50%;
        background-color: #b3b3b3;
        margin-bottom: 3px;
    }
}

.crisp-edges {
    image-rendering: -webkit-optimize-contrast;
    image-rendering: crisp-edges;
  }

.mp-agora-contrainer > div {
    border-radius: 15px;
}