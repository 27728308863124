.wrapper {
    width: 550px;
    border: gainsboro solid 2px;
    border-radius: 4px;
}

.submitBtn {
    width: 100%;
    font-size: 1.2rem;
    background-color: #49BB8C !important;
    border-color: #49BB8C !important;
}