.roomWrapper{
    position: relative;
    min-width: 200px;
    max-width: 600;
    margin-right: 30px;
    border-radius: 20px;
    box-shadow: 0;
    transition: box-shadow 0.5s;
}

.roomDisabled{
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(255,255,255,0.5);
    z-index: 1;
    backdrop-filter: grayscale(1);
}

.roomCallInProgress {
    box-shadow: 0 8px 18px rgba(#dc3545, 0.5);
}

.notifyUnread {
    position: relative;
    left: 5px;
    bottom: 35px;
    z-index: 2;
    height: 20px;
    width: 20px;
    background-color: rgb(255, 0, 0);
    border-radius: 50%;

    & span {
        color: white;
        padding-left: 8px;
    }
}

.messageSquareBox {
    height: 24px;
    width: 24px;
}