.bodyText {
    font-size: 16px;
    margin-bottom: 0.2rem;
    font-weight: 500;
}

.inviteEmails {
    font-weight: 600;
}

.modalWrapper {
    font-size: 16px;
}

.modalHeader {
    h5 {
        font-size: 26px !important;
    }

    button {
        span {
            font-size: 34px;
        }
    }
}

.submitBtn {
    width: 100%;
    font-size: 1.2rem;
    background-color: #49BB8C !important;
    border-color: #49BB8C !important;
}

.mediaBtnActive {
    background-color: rgb(81, 187, 37);
    border-radius: 10px;
    svg {
        color: white;
        vertical-align: middle;
    }
}

.mediaBtn {
    background-color: rgba(81, 187, 37, 0.15);
    border-radius: 10px;
    svg {
        color: rgb(81, 187, 37);
        vertical-align: middle;
    }
}
