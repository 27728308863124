.callAreaWrapper{
    background-color: rgb(242, 242, 242);
    border-radius: 15px;
    margin: 0 30px 30px 0;
}

.gridWrapper{
    margin-top: -15px;
}

.userViewWrapper{
    width: 176px;
    height: 140px;
    margin-bottom: 0;
    margin-top: 15px;
}

.userAvatarWrapper{
    inset: 0;
    width: 130px;
    height: 130px;
}

.userAvatar{
    inset: 0;
    object-fit: 'cover';
}